import { isRejectedWithValue } from "@reduxjs/toolkit";
import { notification } from "antd";
import { resetLocaleStorge } from "utils/helpers";
import { logOut } from "./services/loginService";

/**
 *Show a notification!
 */
export const rtkQueryErrorMiddleware = () => next => action => {
	if (action?.payload?.status === 400) {
		const errorKeys = Object.keys(action?.payload?.data?.errors || {});
		if (errorKeys?.length) {
			errorKeys.forEach(item => {
				const msgItem = action.payload.data?.errors[item];
				if (Array.isArray(msgItem)) {
					msgItem.forEach(msg => {
						notification.error({
							message: msg,
							duration: 3
						});
					});
				} else {
					notification.error({
						message: msgItem,
						duration: 3
					});
				}
			});
		}
	} else if (action?.payload?.status === 500) {
		notification.error({
			message: action.payload?.data?.Message || "Internal Error",
			duration: 2
		});
	} else if (
		action?.payload?.status === 401
		//  || (action.meta?.baseQueryMeta && !action.meta.baseQueryMeta?.response)
	) {
		logOut().finally(() => {
			resetLocaleStorge();
			window.location.href = "/";
		});
	} else if (action?.payload?.data?.Message) {
		notification.error({
			message: action?.payload?.data?.Message,
			duration: 2
		});
	} else if (isRejectedWithValue(action)) {
		notification.error({
			message: action.meta.baseQueryMeta?.response?.statusText || "Internal Error",
			duration: 2
		});
	} else if (action?.payload?.hasError) {
		notification.error({
			message: action.payload.message,
			duration: 2
		});
	}

	return next(action);
};

import { useHistory } from "react-router-dom";
import { notification } from "antd";
import moment from "moment-timezone";
import { logOut } from "app/services/loginService";
import storage from "utils/storage";
import gameIds from "../enums/gameIds";
import { IPv4, IPv6, pageSize } from "./constants";

export function isPermitted(permissions, actionId) {
	return !!permissions.find(item => item.permissionId === actionId && !item.permit);
}

export function convertDateToUserTimezoneNoFormat(date) {
	if (!date) return date;

	const timezone = moment.tz.guess();
	const convertedTime = moment.tz(moment(date), timezone);
	return convertedTime;
}

export function convertDateToUserTimezone(date) {
	if (!date) return date;

	const timezone = moment.tz.guess();
	const convertedTime = moment.tz(moment(date).format("MM/DD/YYYY HH:mm:ss"), timezone);
	return convertedTime;
}

export function getUrlParams(search) {
	const params = new URLSearchParams(search);
	const obj = {};

	// eslint-disable-next-line no-restricted-syntax
	for (const [key, value] of params) {
		obj[key] = value;
	}
	return obj;
}

export function detectAction() {
	const { location } = useHistory();
	return location?.pathname?.split("/")[3];
}

export function stopPropagate(e) {
	e.stopPropagation();
}

export function generateId() {
	return Math.floor(Date.now() * (Math.random() * 1000));
}

export function capitalize(string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function mutateSelectValues(obj, optionsData, isNullCase = true) {
	const clone = { ...obj };
	Object.keys(clone).forEach(field => {
		// in All selected case , backend exept -1
		if (
			isNullCase &&
			Array.isArray(clone[field]) &&
			clone?.[field] &&
			optionsData?.[field] &&
			clone[field]?.length === optionsData[field]?.length &&
			field !== "pagination"
		) {
			clone[field] = null;
		}

		if (clone[field] === "default") {
			clone[field] = null;
		}
	});
	return clone;
}

let debounceTimer;
export async function debounce(callback, wait = 300) {
	clearTimeout(debounceTimer);
	debounceTimer = setTimeout(callback, wait);
}

export const dataWithPaginationQuery = body => {
	if (body["Pagination.PageIndex"]) return body;
	return {
		...body,
		"Pagination.PageIndex": 0,
		"Pagination.pageSize": pageSize
	};
};

export const dataWithPaginationQuery2 = body => {
	const newBody = { ...body };
	const { pageIndex, pageSize: newPageSize } = newBody.pagination || {};
	delete newBody.pagination;

	return {
		...newBody,
		"Pagination.PageIndex": pageIndex || 0,
		"Pagination.PageSize": newPageSize || pageSize
	};
};

export const dataWithPagination = body => {
	if (body.pagination) return body;

	return {
		...body,
		pagination: {
			pageIndex: 0,
			pageSize
		}
	};
};

export const parseNumber = (num, cutDecimal) => {
	if (num) {
		const [numberStr, precisionStr] = `${num}`.split(".");
		if (cutDecimal) {
			if (numberStr[0] === "-" && numberStr[1] === "0") {
				return numberStr.slice(1);
			}
		}

		if (numberStr.length < 4) return num;
		let parsedStr = "";
		let count = 0;

		for (let i = numberStr.length - 1; i >= 0; i--) {
			count++;
			parsedStr = (count % 3 ? numberStr[i] : ` ${numberStr[i]}`) + parsedStr;
		}

		if (precisionStr && !cutDecimal) {
			return `${parsedStr.replace("- ", "-")}.${precisionStr}`;
		}
		return parsedStr.replace("- ", "-");
	}

	return "-";
};

export function generateColumnTitle({ column, gameId }) {
	if (column === "partner") {
		switch (gameId) {
			case gameIds.BlackJack:
				return "Player's Hand Cards";
			case gameIds.LimboCrash:
				return "Target Multiplier";
			case gameIds.DrShocker:
				return "Buy bonus";
			case gameIds.CosmoSaga:
				return "Steps";
			case gameIds.Figoal:
				return "Bonus Mode";
			default:
				return "Selected Combination";
		}
	}

	if (gameId === gameIds.BlackJack) return "Dealer's Hand Cards";
	return "Game Result";
}

export function validatePassword(password = "") {
	return {
		lower: /[a-z]/.test(password),
		upper: /[A-Z]/.test(password),
		number: /\d/.test(password),
		length: password?.length >= 8
	};
}

export function prepareHeaders(headers) {
	// const currencyId = window.localStorage?.getItem("currencyId");
	const token = window.localStorage?.getItem("token");
	headers.set("Access-Control-Allow-Origin", "*");
	headers.set("Token", `${token}`);
	// headers.set("currencyId", `${currencyId || 0}`);
	return headers;
}

export function isValidIP(str, type = 4) {
	if (type === 4) {
		const regex = new RegExp(IPv4);
		return regex.test(str);
	}
	if (type === 6) {
		const regex = new RegExp(IPv6);
		return regex.test(str);
	}
}

export const addToClipboard = text => {
	if (navigator.clipboard) {
		navigator.clipboard?.writeText(text).then(() => {
			notification.success({
				message: "Copied!"
			});
		});
		return;
	}

	const textArea = document.createElement("textarea");
	textArea.value = text;
	textArea.style.position = "fixed";
	textArea.style.left = "-999999px";
	textArea.style.top = "-999999px";
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();
	const copied = document.execCommand("copy");
	textArea.remove();
	if (copied) {
		notification.success({
			message: "Copied!"
		});
	} else {
		notification.error({
			message: "Failed to copy"
		});
	}
};

export const resetLocaleStorge = () => {
	storage?.removeItem("token");
	storage?.removeItem("refreshToken");
	storage?.removeItem("permissions");
};

/**
 * It takes an array of objects and a string, and returns an array of values from the objects based on type
 * @param data - the data that you want to plot
 * @param type - The type of data you want to display.
 * @returns An array of numbers.
 */
export function getSeriesData(data, type) {
	return data?.map(item => item[type]) || [];
}

/**
 * It takes an array of objects and a string as arguments and returns an array of strings ("DD.MM.YYYY" or "Month name").
 * @param data - the data that you want to get the categories from
 * @param type - daily, lastYear
 * @returns An array of strings.
 */
export function getCategories(data, type) {
	switch (type) {
		case "daily":
			return data?.map(item => moment(item.periodDate).format("DD.MM.YYYY")) || [];
		case "lastYear":
			return data?.map(item => moment(item.periodDate).format("MMMM YYYY")) || [];
		default:
			return [];
	}
}

/**
 * It returns an object with two properties, startPoint and endPoint, which are the first and last
 * values of the array of objects passed in, respectively
 * @param data - the data that you want to get the regression line points from
 * @param type - The type of data you want to get the regression line for.
 */
export function getLinePoints(data, type) {
	return data
		? {
				startPoint: Math.round(data?.[0]?.[`${type}RegressionPoint`]),
				endPoint: Math.round(data?.[data.length - 1]?.[`${type}RegressionPoint`])
		  }
		: { startPoint: 0, endPoint: 0 };
}

export const resetMoreSpace = text => {
	if (typeof text !== "string") return text;
	const newText = text
		.split(" ")
		.filter(item => !!item)
		.join(" ");
	return newText;
};

let activityWatcherInterval;
export const activityWatcher = setLastActivity => {
	clearInterval(activityWatcherInterval);
	let lastActivityTimer = 0;
	let logoutTimer = 0;
	let activeUser = true;

	const pingTime = 60 * 5; // 5 minutes
	const maxInactivity = 60 * 30; // 30 minutes

	activityWatcherInterval = setInterval(() => {
		lastActivityTimer++;
		if (lastActivityTimer > pingTime) {
			const token = storage?.getItem("token");
			if (!token) {
				clearTimeout(activityWatcherInterval);
				return;
			}
			if (activeUser) {
				setLastActivity();
				logoutTimer = 0;
			} else {
				logoutTimer += pingTime;
				if (logoutTimer >= maxInactivity) {
					logOut().then(() => {
						resetLocaleStorge();
						window.location.reload();
					});
				}
			}

			lastActivityTimer = 0;
			activeUser = false;
		}
	}, 1000);

	function activity() {
		if (logoutTimer > 1190) {
			setLastActivity();
			logoutTimer = 0;
		}
		activeUser = true;
	}

	["mousedown", "mousemove", "keydown", "scroll", "touchstart"].forEach(eventName => {
		document.addEventListener(eventName, activity, true);
	});
};

export const sortingByAlphabet = (list, key, breakKeys) => {
	if (!Array.isArray(list)) return;
	const newList = [...list];

	return newList.sort((a, b) => {
		if (a.title === "TOTAL" || a.key === "total") return 1;
		if (breakKeys && (breakKeys.includes(a[key]) || breakKeys.includes(b[key]))) {
			return 0;
		}
		if (a[key] < b[key]) {
			return -1;
		}
		if (a[key] > b[key]) {
			return 1;
		}
		return 0;
	});
};

export const sortingByDate = (list, key) => {
	if (!Array.isArray(list)) return;
	const newList = [...list];

	return newList.sort((a, b) => {
		if (!(moment(a[key], "DD.MM.YYYY").isValid() && moment(b[key], "DD.MM.YYYY").isValid())) {
			return 0;
		}
		const aValue = moment(a[key], "DD.MM.YYYY").valueOf();
		const bValue = moment(b[key], "DD.MM.YYYY").valueOf();
		if (aValue < bValue) {
			return -1;
		}
		if (aValue > bValue) {
			return 1;
		}
		return 0;
	});
};

export function dataURItoBlob(dataURI) {
	// convert base64/URLEncoded data component to raw binary data held in a string
	let byteString;
	if (dataURI.split(",")[0].indexOf("base64") >= 0) byteString = atob(dataURI.split(",")[1]);
	else byteString = unescape(dataURI.split(",")[1]);
	// separate out the mime component
	const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

	// write the bytes of the string to a typed array
	const ia = new Uint8Array(byteString.length);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ia], { type: mimeString });
}

const generateUniqId = () => {
	let lastId = 0;

	return (prefix = "id") => {
		lastId++;
		return `${prefix}${lastId}`;
	};
};
export const getUniqId = generateUniqId();

export const generatePagination = (currentPage, totalPages) => {
	const pagination = [];
	const maxVisiblePages = 5; // Adjust this based on how many pages you want to show

	// Helper function to add pages
	const addPage = (start, end) => {
		for (let i = start; i <= end; i++) {
			pagination.push(i);
		}
	};

	// Helper function to add dots
	const addDots = () => {
		pagination.push("...");
	};

	// Add the first page
	addPage(1, 1);

	// If there are more than 5 pages, add dots in the middle
	if (totalPages > maxVisiblePages) {
		if (currentPage > maxVisiblePages - 2) {
			addDots();
		}

		const start = Math.max(2, currentPage - 2);
		const end = Math.min(currentPage + 2, totalPages - 1);

		addPage(start, end);

		if (currentPage < totalPages - maxVisiblePages + 2) {
			addDots();
		}
	} else {
		// If there are 5 or fewer pages, just add all of them
		addPage(2, totalPages - 1);
	}

	// Add the last page
	if (totalPages > 1) {
		addPage(totalPages, totalPages);
	}

	return pagination;
};

// create new array with ids
export const mapIds = (array, key = "id") => {
	if (!Array.isArray(array)) return;

	return array.map(item => item[key]);
};

export const chunkArray = (array, chunkSize) => {
	const result = [];

	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize));
	}

	return result;
};

/**
 * Removes duplicates from an array of IDs imported and compares them with a set of player IDs.
 * @param {Array} data - The array of IDs to be compared and filtered.
 * @param {Array} comparedData - The array of player IDs to compare against.
 * @returns {Array} - An array containing unique IDs that are present in data but not in comparedData.
 */
export const compareAndRemoveDuplicates = (data = [], comparedData = []) => {
	const uniqueSet = new Set(comparedData);
	return data.filter(item => !uniqueSet.has(item));
};

/**
 * Downloads a blob object as a file in the browser.
 * @param {Blob} options.data - The blob object to download.
 * @param {string} options.fileName - The name to save the file as. fileName should include extension like imageName.webp.
 */
export const blobObjectDownloader = ({ data = {}, fileName = "" }) => {
	const blobURL = window.URL.createObjectURL(data);
	const tempLink = document.createElement("a");
	tempLink.href = blobURL;
	tempLink.setAttribute("download", fileName);
	document.body.appendChild(tempLink);
	tempLink.click();
	document.body.removeChild(tempLink);
	window.URL.revokeObjectURL(blobURL);
};

const euroCoin = { id: 51, name: "EUR(coin)", coinRate: 1 };
export const getCurrenciesInfo = (data, partnerIds) => {
	if (!data) return { currencies: [], defaultCurrencyId: "" };
	const uniqueCurrencies = {};
	let list = [];

	if (partnerIds) {
		list = partnerIds.map(item => data[item]);
	} else {
		list = Object.values(data);
	}

	let defaultCurrency = list[0]?.defaultCurrencyId || 51;

	list.forEach(elem => {
		const { currencies, defaultCurrencyId } = elem || { currencies: [], defaultCurrencyId: "" };

		if (defaultCurrency !== defaultCurrencyId) {
			defaultCurrency = euroCoin.id;
		}
		currencies.forEach(item => {
			if (!uniqueCurrencies[item.id]) {
				uniqueCurrencies[item.id] = item;
			}
		});
	});

	return {
		currencies: [euroCoin, ...Object.values(uniqueCurrencies)],
		defaultCurrencyId: defaultCurrency
	};
};

/**
 * Generates a compact number format string for a given number.
 * @param {number} number.
 * @returns {string}.
 * @example
 * generateCompactNumber(1000) // '1K';
 * generateCompactNumber(1230000) // '1.23M';
 */
export const generateCompactNumber = number =>
	Intl.NumberFormat("en", { notation: "compact", maximumFractionDigits: 3 }).format(number);

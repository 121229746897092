import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	AddEmailReceiver,
	AddEmailReceiversGroup,
	ApplyEmailReceiversGroupsToAll,
	DeleteEmailReceiver,
	DeleteEmailReceiverGroup,
	ExportNotifications,
	GetAllFilters,
	GetAllRtps,
	GetChartTopPlayers,
	GetEmailReceivers,
	GetEmailReceiversGroups,
	GetEmailReceiversGroupsById,
	GetGameRtpCharts,
	GetNotifications,
	GetRTPMontoringList,
	GetRTPThreshold,
	RTPMONITORING,
	UpdateEmailReceiver,
	UpdateEmailReceiversGroup,
	UpdateGameRTPById,
	UpdateGameRTPStatus,
	UpdateNotificationById
} from "app/constants";
import { dataWithPaginationQuery2, prepareHeaders } from "utils/helpers";

export const rtpMonitoringSlice = createApi({
	reducerPath: "rtpMonitoring",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${RTPMONITORING}/`,
		prepareHeaders
	}),
	endpoints({ mutation, query }) {
		return {
			getAllFilters: query({
				query: () => GetAllFilters
			}),
			getRTPMontoringList: mutation({
				query: body => ({
					url: GetRTPMontoringList,
					method: "POST",
					body
				})
			}),
			getRTPThreshold: mutation({
				query: () => ({
					url: GetRTPThreshold,
					method: "GET"
				})
			}),
			updateGameRTPStatus: mutation({
				query: id => ({
					url: `${UpdateGameRTPStatus}?gameRtpId=${id}`,
					method: "PUT"
				})
			}),
			updateGameRTPById: mutation({
				query: body => ({
					url: UpdateGameRTPById,
					method: "PUT",
					body
				})
			}),
			getChartTopPlayers: mutation({
				query: ({ days, activeGameId }) => ({
					url: `${GetChartTopPlayers}?ActiveGameId=${activeGameId}&Days=${days}`,
					method: "GET"
				})
			}),
			getGameRtpCharts: mutation({
				query: ({ gameRtpId, calculationPeriodId }) => ({
					url: `${GetGameRtpCharts}?GameRtpId=${gameRtpId}${
						calculationPeriodId ? `&CalculationPeriodId=${calculationPeriodId}` : ""
					}`,
					method: "GET"
				})
			}),
			getNotifications: mutation({
				query: body => ({
					url: GetNotifications,
					method: "POST",
					body
				})
			}),
			updateNotificationById: mutation({
				query: body => ({
					url: UpdateNotificationById,
					method: "PUT",
					body
				})
			}),
			exportNotifications: mutation({
				query: body => ({
					url: ExportNotifications,
					method: "POST",
					body
				})
			}),
			addEmailReceiver: mutation({
				query: body => ({
					url: AddEmailReceiver,
					method: "POST",
					body
				})
			}),
			updateEmailReceiver: mutation({
				query: body => ({
					url: UpdateEmailReceiver,
					method: "PUT",
					body
				})
			}),
			addEmailReceiversGroup: mutation({
				query: body => ({
					url: AddEmailReceiversGroup,
					method: "POST",
					body
				})
			}),
			updateEmailReceiversGroup: mutation({
				query: body => ({
					url: UpdateEmailReceiversGroup,
					method: "PUT",
					body
				})
			}),
			applyEmailReceiversGroupsToAll: mutation({
				query: body => ({
					url: ApplyEmailReceiversGroupsToAll,
					method: "PUT",
					body
				})
			}),
			getEmailReceivers: mutation({
				query: params => ({
					url: GetEmailReceivers,
					method: "GET",
					params: dataWithPaginationQuery2(params)
				})
			}),
			getEmailReceiversGroups: mutation({
				query: ({ pagination: { pageIndex, pageSize } }) => {
					return {
						url: `${GetEmailReceiversGroups}?PageIndex=${pageIndex}&PageSize=${pageSize}`,
						method: "GET"
					};
				}
			}),
			deleteEmailReceiverGroup: mutation({
				query: params => ({
					url: DeleteEmailReceiverGroup,
					method: "DELETE",
					params
				})
			}),
			deleteEmailReceiver: mutation({
				query: params => ({
					url: DeleteEmailReceiver,
					method: "DELETE",
					params
				})
			}),
			getEmailReceiversGroupsById: query({
				query: params => ({
					url: GetEmailReceiversGroupsById,
					method: "GET",
					params
				})
			}),
			getAllRtps: query({
				query: params => ({
					url: GetAllRtps,
					method: "GET",
					params
				})
			})
		};
	}
});

export const {
	useGetAllFiltersQuery,
	useGetRTPMontoringListMutation,
	useGetRTPThresholdMutation,
	useUpdateGameRTPStatusMutation,
	useUpdateGameRTPByIdMutation,
	useGetChartTopPlayersMutation,
	useGetGameRtpChartsMutation,
	useGetNotificationsMutation,
	useUpdateNotificationByIdMutation,
	useExportNotificationsMutation,
	useAddEmailReceiverMutation,
	useUpdateEmailReceiverMutation,
	useAddEmailReceiversGroupMutation,
	useUpdateEmailReceiversGroupMutation,
	useApplyEmailReceiversGroupsToAllMutation,
	useGetEmailReceiversMutation,
	useGetEmailReceiversGroupsMutation,
	useDeleteEmailReceiverGroupMutation,
	useDeleteEmailReceiverMutation,
	useGetEmailReceiversGroupsByIdQuery,
	useGetAllRtpsQuery
} = rtpMonitoringSlice;

import { lazy } from "react";
import ChatPlayers from "components/Layout/Content/ChatConfig/ChatPlayers";
import ChatConfiguration from "components/Layout/Content/ChatConfig/ChatConfiguration";
import ChatMessages from "components/Layout/Content/ChatConfig/ChatMessages";
import ChatContent from "components/Layout/Content/ChatConfig/ChatContent";
import ProfanityLibrary from "components/Layout/Content/ChatConfig/ProfanityLibrary";
import StorageManagement from "components/Layout/Content/ContentManagement/StorageManagement";
import { RTPMonitoringSvg } from "common/Icons";
import { mockedActionIds, mockedUnitIds } from "./models";

// Promotions
const Cashback = lazy(() => import("components/Layout/Content/Promotions/Cashback"));
const Leaderboards = lazy(() => import("components/Layout/Content/Promotions/Leaderboards"));
const LeaderboardsActions = lazy(() =>
	import("components/Layout/Content/Promotions/Leaderboards/GeneralInformation/GeneralInformationActions")
);

// Dashboard
const DashboardTables = lazy(() => import("components/Layout/Content/Dashboard/DashboardTables"));
const DashboardCharts = lazy(() => import("components/Layout/Content/Dashboard/DashboardCharts"));
const CompareData = lazy(() => import("components/Layout/Content/Dashboard/DashboardTables/CompareData"));

// User Management
const Users = lazy(() => import("components/Layout/Content/UserManagement/Users"));
// eslint-disable-next-line import/no-cycle
const UserActions = lazy(() => import("components/Layout/Content/UserManagement/Users/UserActions"));
const PermissionActions = lazy(() =>
	// eslint-disable-next-line import/no-cycle
	import("components/Layout/Content/UserManagement/PermissionGroups/PermissionActions")
);
const PermissionsGroups = lazy(() => import("components/Layout/Content/UserManagement/PermissionGroups"));

// Games
const GamesDrawList = lazy(() => import("components/Layout/Content/Games/DrawList"));
const GamesTicketList = lazy(() => import("components/Layout/Content/Games/TicketList"));
const DrawListView = lazy(() => import("components/Layout/Content/Games/DrawList/DrawListView"));

// Bonuses
const BonusConfiguration = lazy(() => import("components/Layout/Content/Bonuses/BonusConfiguration"));
const BonusCampaigns = lazy(() => import("components/Layout/Content/Bonuses/BonusCampaigns"));
const CreateBonusCampaign = lazy(() => import("components/Layout/Content/Bonuses/BonusCampaigns/CreateBonusCampaign"));
const BonusPlayers = lazy(() => import("components/Layout/Content/Bonuses/BonusPlayers"));

// Configuration
const Currencies = lazy(() => import("components/Layout/Content/Configuration/Currencies"));
const CurrenciesActions = lazy(() => import("components/Layout/Content/Configuration/Currencies/CurrenciesActions"));
const ConfigGames = lazy(() => import("components/Layout/Content/Configuration/ConfigGames"));
const ConfigGamesActions = lazy(() => import("components/Layout/Content/Configuration/ConfigGames/ConfigGamesActions"));
const ConfigPartners = lazy(() => import("components/Layout/Content/Configuration/ConfigPartners"));
const ConfigPartnersActions = lazy(() =>
	import("components/Layout/Content/Configuration/ConfigPartners/ConfigPartnersActions")
);
const GameSkinning = lazy(() => import("components/Layout/Content/Configuration/GameSkinning"));
const GameSkinningActions = lazy(() =>
	import("components/Layout/Content/Configuration/GameSkinning/GameSkinningActions")
);

const TwoFaPartnersConfiguration = lazy(() =>
	import("components/Layout/Content/Configuration/TwoFaPartnersConfiguration")
);

// Logs
const UserLog = lazy(() => import("components/Layout/Content/Logs/User"));
const PlayerLog = lazy(() => import("components/Layout/Content/Logs/Player"));

// Content Management
const Translations = lazy(() => import("components/Layout/Content/ContentManagement/Translations"));
const Rules = lazy(() => import("components/Layout/Content/ContentManagement/Rules"));
const RulesActions = lazy(() => import("components/Layout/Content/ContentManagement/Rules/RulesActions"));

// Reports
const FrequentWinReport = lazy(() => import("components/Layout/Content/Reports/FrequentWinReport"));
const InvoiceReport = lazy(() => import("components/Layout/Content/Reports/InvoiceReport"));
const LeaderboardPlayersReport = lazy(() => import("components/Layout/Content/Reports/LeaderboardPlayersReport"));
const LightningBonusReport = lazy(() => import("components/Layout/Content/Reports/LightningBonusReport"));
const LeaderboardReport = lazy(() => import("components/Layout/Content/Reports/LeaderboardReport"));

// Risk Management
const Campaigns = lazy(() => import("components/Layout/Content/RiskManagement/Campaigns"));
const Lists = lazy(() => import("components/Layout/Content/RiskManagement/Lists"));
const Alerts = lazy(() => import("components/Layout/Content/RiskManagement/Alerts"));
const MaxWinners = lazy(() => import("components/Layout/Content/RiskManagement/MaxWinners"));
const CampaignActions = lazy(() => import("components/Layout/Content/RiskManagement/Campaigns/CampaignActions"));
const RecipientsListsActions = lazy(() =>
	import("components/Layout/Content/RiskManagement/Lists/RecipientLists/RecipientListsActions")
);
const PlayersListActions = lazy(() =>
	import("components/Layout/Content/RiskManagement/Lists/PlayersList/PlayersListActions")
);

// Play Link
const PartnerConfiguration = lazy(() => import("components/Layout/Content/PlayLink/PartnerConfiguration"));
const PartnerConfigurationActions = lazy(() =>
	import("components/Layout/Content/PlayLink/PartnerConfiguration/PartnerConfigurationActions")
);

// RTP Monitoring
const MonitoringTable = lazy(() => import("components/Layout/Content/RTPMonitoring/MonitoringTable"));
const GameRTPChart = lazy(() => import("components/Layout/Content/RTPMonitoring/GameRTPChart"));
const Notifications = lazy(() => import("components/Layout/Content/RTPMonitoring/Notifications"));
const RTPThreshold = lazy(() => import("components/Layout/Content/RTPMonitoring/RTPThreshold"));
const UsersList = lazy(() => import("components/Layout/Content/RTPMonitoring/UsersList"));
const UsersListActions = lazy(() => import("components/Layout/Content/RTPMonitoring/UsersList/Lists/UsersListActions"));

const menuData = [
	{
		name: "Dashboard",
		path: "dashboard",
		unitId: mockedUnitIds.Dashboard,
		icon: "Dashboard",
		menuPosition: 1,
		submenu: [
			{
				id: mockedUnitIds.Tables, // temp
				path: "dashboard/tables",
				name: "Tables",
				component: DashboardTables,
				actions: [
					{
						actionId: 3,
						path: "dashboard/tables/compareData",
						component: CompareData
					}
				]
			},
			{
				id: mockedUnitIds.Charts, // temp
				path: "dashboard/charts",
				name: "Charts",
				component: DashboardCharts
			}
		]
	},
	{
		name: "User Management",
		unitId: mockedUnitIds.UserManagment,
		icon: "Management",
		menuPosition: 2,
		submenu: [
			{
				id: mockedUnitIds.Users, // temp
				path: "userconfigs/users",
				name: "Users",
				component: Users,
				actions: [
					{
						actionId: 1,
						path: "userconfigs/users/create",
						component: UserActions
					},
					{
						actionId: 2,
						path: "userconfigs/users/edit/:id",
						component: UserActions
					},
					{
						actionId: 3,
						path: "userconfigs/users/view/:id",
						component: UserActions
					}
				]
			},

			{
				id: mockedUnitIds.PermissionGroups, // temp
				path: "userconfigs/permission",
				name: "Permission Groups",
				component: PermissionsGroups,
				actions: [
					{
						actionId: 1,
						path: "userconfigs/permission/create",
						component: PermissionActions
					},
					{
						actionId: 2,
						path: "userconfigs/permission/edit/:id",
						component: PermissionActions
					},
					{
						actionId: 3,
						path: "userconfigs/permission/view/:id",
						component: PermissionActions
					}
				]
			}
		]
	},
	{
		unitId: mockedUnitIds.Games,
		path: "games",
		name: "Games",
		icon: "Games",
		menuPosition: 3,
		submenu: [
			{
				id: mockedUnitIds.DrawList,
				path: "games/draw",
				name: "Draw List",
				component: GamesDrawList,
				actions: [
					{
						actionId: 3,
						path: "games/draw/view/:gameTypeName/:currencyName/:drawId/:partnerId/:coinRate/:precision",
						component: DrawListView
					}
				]
			},
			{
				id: mockedUnitIds.TicketList,
				path: "games/ticket",
				name: "Ticket List",
				component: GamesTicketList,
				actions: [
					{
						actionId: 3,
						path: "games/ticket/view/:gameTypeName/:currencyName/:drawId/:partnerId/:coinRate/:precision",
						component: DrawListView
					}
				]
			}
		]
	},
	{
		unitId: mockedUnitIds.Bonuses,
		path: "bonuses",
		name: "Bonuses",
		icon: "Bonus",
		menuPosition: 4,
		submenu: [
			{
				id: mockedUnitIds.BonusTypes,
				path: "bonuses/types",
				name: "Bonus Configurations",
				component: BonusConfiguration,
				actions: [
					{
						actionId: mockedActionIds.edit,
						path: "bonuses/bonusCampaigns/edit/:id",
						component: CreateBonusCampaign
					},
					{
						actionId: mockedActionIds.add,
						path: "bonuses/bonusCampaigns/create/:id",
						component: CreateBonusCampaign
					}
				]
			},
			{
				id: mockedUnitIds.BonusCampaigns,
				path: "bonuses/bonusCampaigns",
				name: "Campaigns",
				component: BonusCampaigns,
				actions: [
					{
						actionId: mockedActionIds.add,
						path: "bonuses/bonusCampaigns/create",
						component: CreateBonusCampaign
					}
				]
			},
			{
				id: mockedUnitIds.PlayerBonuses,
				path: "bonuses/playerFreeBet",
				name: "Player Bonuses",
				component: BonusPlayers
			}
		]
	},
	{
		unitId: mockedUnitIds.Promotions,
		path: "promotions",
		name: "Promotions",
		icon: "Promotions",
		menuPosition: 5,
		submenu: [
			{
				id: mockedUnitIds.Cashback,
				path: "promotions/cashback",
				name: "Lightning Bonus",
				component: Cashback
			},
			{
				id: mockedUnitIds.Leaderboards,
				path: "promotions/leaderboards",
				name: "Leaderboard",
				component: Leaderboards,
				actions: [
					{
						actionId: 1,
						path: "promotions/leaderboards/copy/:id",
						component: LeaderboardsActions
					},
					{
						actionId: 1,
						path: "promotions/leaderboards/create",
						component: LeaderboardsActions
					},
					{
						actionId: 2,
						path: "promotions/leaderboards/edit/:id/:status",
						component: LeaderboardsActions
					},
					{
						actionId: 3,
						path: "promotions/leaderboards/view/:id/:status",
						component: LeaderboardsActions
					}
				]
			}
		]
	},
	{
		unitId: mockedUnitIds.Logs,
		path: "logs",
		name: "Logs",
		icon: "Logs",
		menuPosition: 8,
		submenu: [
			{
				id: mockedUnitIds.UserLog,
				path: "logs/user",
				name: "User Log",
				component: UserLog
			},
			{
				id: mockedUnitIds.PlayerLog,
				path: "logs/player",
				name: "Player Bet Log",
				component: PlayerLog
			}
		]
	},
	{
		unitId: mockedUnitIds.Reports,
		path: "reports",
		name: "Reports",
		icon: "Report",
		menuPosition: 6,
		submenu: [
			{
				id: mockedUnitIds.FrequentWinReport,
				path: "reports/FrequentWinReport",
				name: "Frequent Win Report",
				component: FrequentWinReport
			},
			{
				id: mockedUnitIds.InvoiceReport,
				path: "reports/invoiceReport",
				name: "Invoice Report",
				component: InvoiceReport
			},
			{
				id: mockedUnitIds.LightningBonusReport,
				path: "reports/lightningBonus",
				name: "Lightning Bonus Report",
				component: LightningBonusReport
			},
			{
				id: mockedUnitIds.LeaderboardPlayersReport,
				path: "reports/leaderboardPlayers",
				name: "Leaderboard Players",
				component: LeaderboardPlayersReport
			},
			{
				id: mockedUnitIds.LeaderboardReport,
				path: "reports/leaderboardReports",
				name: "Leaderboard Report",
				component: LeaderboardReport
			}
		]
	},
	{
		unitId: mockedUnitIds.Configuration,
		path: "configs",
		name: "Configuration",
		icon: "Configs",
		menuPosition: 9,
		submenu: [
			{
				id: mockedUnitIds.Partners,
				path: "configs/partners",
				name: "Partners",
				component: ConfigPartners,
				actions: [
					{
						actionId: 1,
						path: "configs/partners/create",
						component: ConfigPartnersActions
					},
					{
						actionId: 2,
						path: "configs/partners/edit/:id",
						component: ConfigPartnersActions
					}
				]
			},
			{
				id: mockedUnitIds.ConfigurationGames,
				path: "configs/games",
				name: "Games",
				component: ConfigGames,
				actions: [
					{
						actionId: 1,
						path: "configs/games/create",
						component: ConfigGamesActions
					},
					{
						actionId: 2,
						path: "configs/games/edit/:PartnerId/:GameTypeId",
						component: ConfigGamesActions
					},
					{
						actionId: 3,
						path: "configs/games/view/:id",
						component: ConfigGamesActions
					}
				]
			},
			{
				id: mockedUnitIds.GameSkinning,
				path: "configs/gameSkinning",
				name: "Game Skinning",
				component: GameSkinning,
				actions: [
					{
						actionId: mockedActionIds.add,
						path: "configs/gameSkinning/create",
						component: GameSkinningActions
					},
					{
						actionId: mockedActionIds.edit,
						path: "configs/gameSkinning/edit/:id",
						component: GameSkinningActions
					},
					{
						actionId: mockedActionIds.view,
						path: "configs/gameSkinning/view/:id",
						component: GameSkinningActions
					}
				]
			},
			{
				id: mockedUnitIds.Currencies,
				path: "configs/currencies",
				name: "Currencies",
				component: Currencies,
				actions: [
					{
						actionId: 1,
						path: "configs/currencies/create",
						component: CurrenciesActions
					},
					{
						actionId: 2,
						path: "configs/currencies/edit/:id",
						component: CurrenciesActions
					},
					{
						actionId: 3,
						path: "configs/currencies/view/:id",
						component: CurrenciesActions
					}
				]
			},
			{
				id: mockedUnitIds.TwoFA,
				path: "configs/twofa",
				name: "2FA",
				component: TwoFaPartnersConfiguration
			}
		]
	},
	{
		unitId: mockedUnitIds.ContentManagement,
		path: "content",
		name: "Content Management",
		icon: "Content_Management",
		menuPosition: 10,
		submenu: [
			{
				id: mockedUnitIds.Rules,
				path: "content/rules",
				name: "Rules Management",
				component: Rules,
				actions: [
					{
						actionId: mockedActionIds.add,
						path: "content/rules/create",
						component: RulesActions
					},
					{
						actionId: mockedActionIds.add,
						path: "content/rules/copy/:id",
						component: RulesActions
					},
					{
						actionId: mockedActionIds.edit,
						path: "content/rules/edit/:id",
						component: RulesActions
					},
					{
						actionId: mockedActionIds.view,
						path: "content/rules/view/:id",
						component: RulesActions
					},
					{
						actionId: mockedActionIds.view,
						path: "content/rules/viewHistory/:id",
						component: RulesActions
					}
				]
			},
			{
				id: mockedUnitIds.Translations,
				path: "content/translations",
				name: "Translations",
				component: Translations
			},
			{
				id: mockedUnitIds.StorageManagement,
				path: "content/storageManagement",
				name: "Storage Management",
				component: StorageManagement
			}
		]
	},
	{
		unitId: mockedUnitIds.Chat,
		path: "chat",
		name: "Chat",
		icon: "Chat",
		menuPosition: 11,
		submenu: [
			{
				id: mockedUnitIds.ChatPlayers,
				path: "chat/player",
				name: "Chat Players",
				component: ChatPlayers
			},
			{
				id: mockedUnitIds.ChatConfiguration,
				path: "chat/configuration",
				name: "Chat Configuration",
				component: ChatConfiguration
			},
			{
				id: mockedUnitIds.ChatMessages,
				path: "chat/messages",
				name: "Chat Messages",
				component: ChatMessages
			},
			{
				id: mockedUnitIds.ChatContent,
				path: "chat/content",
				name: "Chat Content",
				component: ChatContent
			},
			{
				id: mockedUnitIds.ProfanityLibrary,
				path: "chat/profanity",
				name: "Profanity Library",
				component: ProfanityLibrary
			}
		]
	},
	{
		unitId: mockedUnitIds.RiskManagement,
		path: "riskManagement",
		name: "Risk Management",
		icon: "riskManagement",
		menuPosition: 7,
		submenu: [
			{
				id: mockedUnitIds.Campaigns,
				path: "riskManagement/campaigns",
				name: "Campaigns",
				component: Campaigns,
				actions: [
					{
						actionId: 1,
						path: "riskManagement/campaigns/create",
						component: CampaignActions
					},
					{
						actionId: 2,
						path: "riskManagement/campaigns/edit/:id",
						component: CampaignActions
					},
					{
						actionId: 3,
						path: "riskManagement/campaigns/view/:id",
						component: CampaignActions
					}
				]
			},
			{
				id: mockedUnitIds.Lists,
				path: "riskManagement/lists",
				name: "Lists",
				component: Lists,
				actions: [
					// Recipient List
					{
						actionId: 1,
						path: "riskManagement/lists/recipientLists/create",
						component: RecipientsListsActions
					},
					{
						actionId: 2,
						path: "riskManagement/lists/recipientLists/edit/:id",
						component: RecipientsListsActions
					},
					{
						actionId: 3,
						path: "riskManagement/lists/recipientLists/view/:id",
						component: RecipientsListsActions
					},
					// Players List
					{
						actionId: 1,
						path: "riskManagement/lists/playersList/create",
						component: PlayersListActions
					},
					{
						actionId: 2,
						path: "riskManagement/lists/playersList/edit/:id",
						component: PlayersListActions
					},
					{
						actionId: 3,
						path: "riskManagement/lists/playersList/view/:id",
						component: PlayersListActions
					}
				]
			},
			{
				id: mockedUnitIds.Alerts,
				path: "riskManagement/alerts",
				name: "Alerts",
				component: Alerts
			},
			{
				id: mockedUnitIds.MaxWinners,
				path: "riskManagement/maxWinners",
				name: "Max Winners",
				component: MaxWinners
			}
		]
	},
	{
		unitId: mockedUnitIds.PlayLink,
		path: "playLink",
		name: "Play Link",
		icon: "PlayLink",
		menuPosition: 12,
		submenu: [
			{
				id: mockedUnitIds.PartnerConfiguration,
				path: "playLink/partnerConfiguration",
				name: "Partner Configuration",
				component: PartnerConfiguration,
				actions: [
					{
						actionId: 1,
						path: "playLink/partnerConfiguration/create",
						component: PartnerConfigurationActions
					},
					{
						actionId: 2,
						path: "playLink/partnerConfiguration/edit/:id",
						component: PartnerConfigurationActions
					},
					{
						actionId: 3,
						path: "playLink/partnerConfiguration/view/:id",
						component: PartnerConfigurationActions
					}
				]
			}
		]
	},
	{
		unitId: mockedUnitIds.RTPMonitoring,
		path: "rtpMonitoring",
		name: "RTP Monitoring Tool",
		icon: <RTPMonitoringSvg />,
		menuPosition: 13,
		submenu: [
			{
				id: mockedUnitIds.MonitoringTable,
				path: "rtpMonitoring/monitoringTable",
				name: "Monitoring Table",
				component: MonitoringTable
			},
			{
				id: mockedUnitIds.GameRTPChart,
				path: "rtpMonitoring/gameRtpChart",
				name: "Game RTP Chart",
				component: GameRTPChart,
				exact: false
			},
			{
				id: mockedUnitIds.Notifications,
				path: "rtpMonitoring/notifications",
				name: "Notifications",
				component: Notifications
			},
			{
				id: mockedUnitIds.RTPThreshold,
				path: "rtpMonitoring/rtpThreshold",
				name: "RTP Threshold",
				component: RTPThreshold
			},
			{
				id: mockedUnitIds.UsersList,
				path: "rtpMonitoring/usersList",
				name: "Users List",
				component: UsersList,
				actions: [
					{
						actionId: 1,
						path: "rtpMonitoring/usersList/create",
						component: UsersListActions
					},
					{
						actionId: 2,
						path: "rtpMonitoring/usersList/edit/:id",
						component: UsersListActions
					},
					{
						actionId: 3,
						path: "rtpMonitoring/usersList/view/:id",
						component: UsersListActions
					}
				]
			}
		]
	}
];

export function mergeTwoMenu(unsorted = []) {
	const parentUnits = [];
	Object.values(unsorted).forEach(element => {
		const values = Object.values(element);
		let submenuList = [];
		for (let index = 1; index < values.length; index++) {
			const subMenu = values[index];
			if (subMenu.unitId === mockedUnitIds.PermissionGroups) {
				if (subMenu.permissions.find(item => item.permissionId === 3)) {
					submenuList.push(subMenu);
				}
			} else if (subMenu.permissions.find(item => item.permissionId === 3)) {
				submenuList.push(subMenu);
			}
		}
		if (submenuList.length > 0) {
			parentUnits.push({
				...values[0],
				submenu: [...submenuList]
			});
			submenuList = [];
		}
	});

	return parentUnits
		.map(item => {
			const findPermissionIndex = menuData.findIndex(menu => menu.unitId === item.unitId);
			if (findPermissionIndex !== -1) {
				const { icon, path, submenu, menuPosition } = menuData[findPermissionIndex];

				if (item.submenu.length) {
					const subs = [];

					submenu.forEach(sub => {
						const realSub = item.submenu.find(el => el.unitId === sub.id);
						if (realSub) {
							const { path: submenuPath, component, actions } = sub;

							subs.push({ ...realSub, path: submenuPath, component, actions });
						}
					});
					item.submenu = subs;
				}

				return { ...item, icon, path, menuPosition };
			}
			return null;
		})
		.filter(item => item?.unitId)
		.sort((a, b) => a.menuPosition - b.menuPosition);
}

export default menuData;

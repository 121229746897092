import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
	REPORTING,
	GET_FREQUENT_WINS,
	GET_FILTERS_FOR_FREQUENT_WINS,
	EXPORT_FREQUENT_WINS_BY_PLAYER,
	GET_FILTERS_FOR_INVOICE,
	GET_INVOICE_REPORT,
	EXPORT_INVOICE_REPORT
} from "app/constants";
import { dataWithPagination, prepareHeaders } from "utils/helpers";

export const reportingApiSlice = createApi({
	reducerPath: "reporting",
	baseQuery: fetchBaseQuery({
		baseUrl: `${process.env.REACT_APP_BASE_URL}${REPORTING}/`,
		prepareHeaders
	}),
	keepUnusedDataFor: 0,
	endpoints({ query, mutation }) {
		return {
			getFiltersForFrequentWins: query({
				query: () => GET_FILTERS_FOR_FREQUENT_WINS
			}),
			getFrequentWins: mutation({
				query: body => ({
					url: GET_FREQUENT_WINS,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			exportFrequentWinsByPlayer: mutation({
				query: body => ({
					url: EXPORT_FREQUENT_WINS_BY_PLAYER,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			getFiltersForInvoice: query({
				query: () => GET_FILTERS_FOR_INVOICE
			}),
			getInvoiceReport: mutation({
				query: body => ({
					url: GET_INVOICE_REPORT,
					method: "POST",
					body: dataWithPagination(body)
				})
			}),
			exportInvoiceReport: mutation({
				query: body => ({
					url: EXPORT_INVOICE_REPORT,
					method: "POST",
					body: dataWithPagination(body)
				})
			})
		};
	}
});

export const {
	useGetFiltersForFrequentWinsQuery,
	useGetFrequentWinsMutation,
	useExportFrequentWinsByPlayerMutation,
	useGetFiltersForInvoiceQuery,
	useGetInvoiceReportMutation,
	useExportInvoiceReportMutation
} = reportingApiSlice;

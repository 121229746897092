import { NavLink, useHistory, withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import menuData from "utils/menu";
import PropTypes from "prop-types";
import ErrorBoundary from "common/ErrorBoundary";

function BreadCrumbs({ match }) {
	const { location } = useHistory();

	function breadCrumbData() {
		if (location.pathname) {
			const splittedPath = location?.pathname.split("/").filter(i => Number.isNaN(+i[0]) && i !== "");
			const rootPath = `${splittedPath[0]}/${splittedPath[1]}`;
			const rootRoute = menuData.find(item => item.submenu.find(i => i.path.includes(rootPath)));
			const crumbs = [{ name: rootRoute?.name }];

			if (splittedPath.length > 1) {
				splittedPath
					.slice(1)
					.forEach(item =>
						crumbs.push({ name: rootRoute?.submenu?.find(menu => menu.path.includes(item))?.name || item })
					);
			}
			return crumbs;
		}
	}

	return (
		<ErrorBoundary>
			<Breadcrumb style={{ padding: "16px 0", fontWeight: 500 }}>
				{breadCrumbData().map((item, index) => (
					<Breadcrumb.Item key={index}>
						{item.path ? <NavLink to={`${match.url}${item.path}`}>{item.name}</NavLink> : item.name}
					</Breadcrumb.Item>
				))}
			</Breadcrumb>
		</ErrorBoundary>
	);
}

BreadCrumbs.defaultProps = {
	match: {}
};

BreadCrumbs.propTypes = {
	match: PropTypes.object
};

export default withRouter(BreadCrumbs);

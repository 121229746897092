export const mockedUnitIds = Object.freeze({
	Dashboard: 1,
	Tables: 2,
	Charts: 3,
	UserManagment: 4,
	Users: 5,
	PermissionGroups: 6,
	Games: 7,
	DrawList: 8,
	TicketList: 9,
	Bonuses: 10,
	BonusTypes: 11,
	PlayerBonuses: 12,
	Logs: 13,
	UserLog: 14,
	PlayerLog: 15,
	Reports: 16,
	FrequentWinReport: 17,
	InvoiceReport: 18,
	Configuration: 19,
	Partners: 20,
	ConfigurationGames: 21,
	TwoFA: 22,
	ContentManagement: 23,
	Rules: 24,
	Translations: 25,
	Promotions: 26,
	Cashback: 27,
	Leaderboards: 28,
	Currencies: 29,
	Chat: 30,
	ChatPlayers: 31,
	ChatConfiguration: 32,
	ChatMessages: 33,
	ChatContent: 34,
	ProfanityLibrary: 35,
	RiskManagement: 36,
	Campaigns: 37,
	Lists: 38,
	Alerts: 39,
	LeaderboardPlayersReport: 40,
	LightningBonusReport: 41,
	PlayLink: 42,
	PartnerConfiguration: 43,
	BonusCampaigns: 44,
	StorageManagement: 45,
	MaxWinners: 46,
	RTPMonitoring: 47,
	GameSkinning: 48,
	MonitoringTable: 49,
	GameRTPChart: 50,
	Notifications: 51,
	RTPThreshold: 52,
	UsersList: 53,
    LeaderboardReport: 54
});

export const mockedActionIds = Object.freeze({
	add: 1,
	edit: 2,
	view: 3,
	export: 4
});

export const headTitles = Object.freeze([
	{ permit: false, permissionId: 1, permissionName: "Add" },
	{ permit: false, permissionId: 2, permissionName: "Edit" },
	{ permit: false, permissionId: 3, permissionName: "View" },
	{ permit: false, permissionId: 4, permissionName: "Import/Export" }
]);

export const removedItemsFromMenu = Object.freeze([]);

// Can you added only [3 (view), 4 (export)] required perissions.
export const requiredPermissions = Object.freeze({});

export const sensitivityLevels = Object.freeze({
	[mockedUnitIds.Tables]: {
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.Charts]: {
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.Users]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.PermissionGroups]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.DrawList]: {
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.TicketList]: {
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.BonusTypes]: {
		[mockedActionIds.add]: 5,
		[mockedActionIds.edit]: 5,
		[mockedActionIds.view]: 4,
		[mockedActionIds.export]: 5
	},
	[mockedUnitIds.PlayerBonuses]: {
		[mockedActionIds.edit]: 5,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.UserLog]: {
		[mockedActionIds.view]: 5
	},
	[mockedUnitIds.PlayerLog]: {
		[mockedActionIds.add]: 6,
		[mockedActionIds.edit]: 6,
		[mockedActionIds.view]: 5
	},
	[mockedUnitIds.FrequentWinReport]: {
		[mockedActionIds.view]: 4,
		[mockedActionIds.export]: 5
	},
	[mockedUnitIds.InvoiceReport]: {
		[mockedActionIds.view]: 4,
		[mockedActionIds.export]: 5
	},
	[mockedUnitIds.Partners]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 6
	},
	[mockedUnitIds.ConfigurationGames]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 6
	},
	[mockedUnitIds.TwoFA]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 7
	},
	[mockedUnitIds.Rules]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 3,
		[mockedActionIds.export]: 7
	},
	[mockedUnitIds.Translations]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 3,
		[mockedActionIds.export]: 7
	},
	[mockedUnitIds.Cashback]: {
		[mockedActionIds.add]: 5,
		[mockedActionIds.edit]: 5,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.Leaderboards]: {
		[mockedActionIds.add]: 5,
		[mockedActionIds.edit]: 5,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.ChatPlayers]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.ChatConfiguration]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.ChatMessages]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.ChatContent]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4
	},
	[mockedUnitIds.ProfanityLibrary]: {
		[mockedActionIds.add]: 7,
		[mockedActionIds.edit]: 7,
		[mockedActionIds.view]: 4,
		[mockedActionIds.export]: 7
	}
});

export const currenciesData = {
	1: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	2: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 47, name: "EGP", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 133, name: "RUB", isDefault: false },
			{ id: 139, name: "SEK", isDefault: false },
			{ id: 154, name: "TND", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	4: {
		defaultCurrencyId: 133,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 133, name: "RUB", isDefault: true }
		]
	},
	5: {
		defaultCurrencyId: 157,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true }
		]
	},
	8: {
		defaultCurrencyId: 157,
		currencies: [
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	9: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	11: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 91, name: "LBP", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false },
			{ id: 165, name: "USD", isDefault: true }
		]
	},
	12: {
		defaultCurrencyId: 133,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 133, name: "RUB", isDefault: true },
			{ id: 163, name: "UAH", isDefault: false },
			{ id: 153, name: "TMT", isDefault: false }
		]
	},
	13: {
		defaultCurrencyId: 4,
		currencies: [
			{ id: 4, name: "AMD", isDefault: true },
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 167, name: "USDT", isDefault: false }
		]
	},
	14: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	18: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 131, name: "RON", isDefault: false },
			{ id: 133, name: "RUB", isDefault: false },
			{ id: 163, name: "UAH", isDefault: false },
			{ id: 165, name: "USD", isDefault: true },
			{ id: 47, name: "EGP", isDefault: false },
			{ id: 69, name: "IDR", isDefault: false },
			{ id: 73, name: "IQD", isDefault: false },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 139, name: "SEK", isDefault: false },
			{ id: 154, name: "TND", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false }
		]
	},
	20: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 193, name: "kVND", isDefault: false }
		]
	},
	21: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 30, name: "CAD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	23: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	24: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	25: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	27: {
		defaultCurrencyId: 133,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 133, name: "RUB", isDefault: true }
		]
	},
	28: {
		defaultCurrencyId: 157,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	29: {
		defaultCurrencyId: 2,
		currencies: [
			{ id: 2, name: "AFN", isDefault: true },
			{ id: 1, name: "AED", isDefault: false },
			{ id: 3, name: "ALL", isDefault: false }
		]
	},
	30: {
		defaultCurrencyId: 157,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	31: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	32: { defaultCurrencyId: 51, currencies: [{ id: 51, name: "EUR", isDefault: true }] },
	34: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	35: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	36: {
		defaultCurrencyId: 157,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true }
		]
	},
	37: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	38: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 75, name: "IRT", isDefault: true }
		]
	},
	39: {
		defaultCurrencyId: 135,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 135, name: "SAR", isDefault: true },
			{ id: 130, name: "QAR", isDefault: false },
			{ id: 1, name: "AED", isDefault: false }
		]
	},
	41: {
		defaultCurrencyId: 124,
		currencies: [
			{ id: 33, name: "CLP", isDefault: false },
			{ id: 124, name: "PEN", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	43: {
		defaultCurrencyId: 113,
		currencies: [
			{ id: 7, name: "ARS", isDefault: false },
			{ id: 22, name: "BRL", isDefault: false },
			{ id: 33, name: "CLP", isDefault: false },
			{ id: 36, name: "CRC", isDefault: false },
			{ id: 45, name: "DOP", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 61, name: "GTQ", isDefault: false },
			{ id: 65, name: "HNL", isDefault: false },
			{ id: 113, name: "MXN", isDefault: true },
			{ id: 118, name: "NIO", isDefault: false },
			{ id: 124, name: "PEN", isDefault: false },
			{ id: 129, name: "PYG", isDefault: false },
			{ id: 165, name: "USD", isDefault: false },
			{ id: 168, name: "UYU", isDefault: false }
		]
	},
	45: {
		defaultCurrencyId: 2,
		currencies: [
			{ id: 4, name: "AMD", isDefault: false },
			{ id: 2, name: "AFN", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	46: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	47: {
		defaultCurrencyId: 22,
		currencies: [
			{ id: 21, name: "BOB", isDefault: false },
			{ id: 22, name: "BRL", isDefault: true },
			{ id: 35, name: "COP", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	48: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	49: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 22, name: "BRL", isDefault: false },
			{ id: 35, name: "COP", isDefault: false },
			{ id: 51, name: "EUR", isDefault: true },
			{ id: 133, name: "RUB", isDefault: false },
			{ id: 154, name: "TND", isDefault: false },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	50: {
		defaultCurrencyId: 86,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 172, name: "VND", isDefault: false },
			{ id: 86, name: "KRW", isDefault: true },
			{ id: 117, name: "NGN", isDefault: false }
		]
	},
	51: {
		defaultCurrencyId: 86,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 86, name: "KRW", isDefault: true }
		]
	},
	52: {
		defaultCurrencyId: 86,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 86, name: "KRW", isDefault: true }
		]
	},
	53: {
		defaultCurrencyId: 86,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 86, name: "KRW", isDefault: true }
		]
	},
	59: { defaultCurrencyId: 163, currencies: [{ id: 163, name: "UAH", isDefault: true }] },
	60: {
		defaultCurrencyId: 34,
		currencies: [
			{ id: 34, name: "CNY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	61: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 75, name: "IRT", isDefault: true }
		]
	},
	62: { defaultCurrencyId: 154, currencies: [{ id: 154, name: "TND", isDefault: true }] },
	64: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	66: { defaultCurrencyId: 132, currencies: [{ id: 132, name: "RSD", isDefault: true }] },
	67: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	68: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	69: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	71: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	72: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 54, name: "GBP", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	74: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	75: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	76: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	77: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	78: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	79: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 165, name: "USD", isDefault: true },
			{ id: 34, name: "CNY", isDefault: false },
			{ id: 80, name: "JPY", isDefault: false },
			{ id: 86, name: "KRW", isDefault: false },
			{ id: 161, name: "TWD", isDefault: false }
		]
	},
	80: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	81: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	82: {
		defaultCurrencyId: 7,
		currencies: [
			{ id: 7, name: "ARS", isDefault: false },
			{ id: 22, name: "BRL", isDefault: false },
			{ id: 33, name: "CLP", isDefault: false },
			{ id: 35, name: "COP", isDefault: false },
			{ id: 124, name: "PEN", isDefault: false },
			{ id: 154, name: "TND", isDefault: false },
			{ id: 165, name: "USD", isDefault: true }
		]
	},
	83: {
		defaultCurrencyId: 3,
		currencies: [
			{ id: 3, name: "ALL", isDefault: false },
			{ id: 15, name: "BGN", isDefault: false },
			{ id: 35, name: "COP", isDefault: false },
			{ id: 36, name: "CRC", isDefault: false },
			{ id: 55, name: "GEL", isDefault: false },
			{ id: 143, name: "SLL", isDefault: false },
			{ id: 154, name: "TND", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false },
			{ id: 163, name: "UAH", isDefault: false },
			{ id: 165, name: "USD", isDefault: true }
		]
	},
	84: { defaultCurrencyId: 154, currencies: [{ id: 154, name: "TND", isDefault: true }] },
	85: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: true },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	86: { defaultCurrencyId: 36, currencies: [{ id: 36, name: "CRC", isDefault: true }] },
	87: { defaultCurrencyId: 154, currencies: [{ id: 154, name: "TND", isDefault: true }] },
	88: {
		defaultCurrencyId: 3,
		currencies: [
			{ id: 3, name: "ALL", isDefault: false },
			{ id: 15, name: "BGN", isDefault: false },
			{ id: 35, name: "COP", isDefault: false },
			{ id: 55, name: "GEL", isDefault: false },
			{ id: 143, name: "SLL", isDefault: false },
			{ id: 154, name: "TND", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false },
			{ id: 163, name: "UAH", isDefault: false },
			{ id: 165, name: "USD", isDefault: true }
		]
	},
	89: { defaultCurrencyId: 75, currencies: [{ id: 75, name: "IRT", isDefault: true }] },
	90: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	92: {
		defaultCurrencyId: 157,
		currencies: [
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	93: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	94: { defaultCurrencyId: 143, currencies: [{ id: 143, name: "SLL", isDefault: true }] },
	95: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	97: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	98: {
		defaultCurrencyId: 28,
		currencies: [
			{ id: 28, name: "BYN", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 89, name: "KZT", isDefault: false },
			{ id: 133, name: "RUB", isDefault: true },
			{ id: 163, name: "UAH", isDefault: false },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	99: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	100: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	101: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	102: {
		defaultCurrencyId: 8,
		currencies: [
			{ id: 8, name: "AUD", isDefault: false },
			{ id: 34, name: "CNY", isDefault: false },
			{ id: 40, name: "CZK", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 72, name: "INR", isDefault: false },
			{ id: 80, name: "JPY", isDefault: false },
			{ id: 86, name: "KRW", isDefault: false },
			{ id: 121, name: "NZD", isDefault: false },
			{ id: 128, name: "PLN", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false },
			{ id: 165, name: "USD", isDefault: true }
		]
	},
	103: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	104: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	105: { defaultCurrencyId: 75, currencies: [{ id: 75, name: "IRT", isDefault: true }] },
	107: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	108: { defaultCurrencyId: 157, currencies: [{ id: 157, name: "TRY", isDefault: true }] },
	109: { defaultCurrencyId: 154, currencies: [{ id: 154, name: "TND", isDefault: true }] },
	110: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	111: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	112: {
		defaultCurrencyId: 75,
		currencies: [
			{ id: 75, name: "IRT", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	113: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 157, name: "TRY", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	195: { defaultCurrencyId: 51, currencies: [{ id: 51, name: "EUR", isDefault: true }] },
	217: {
		defaultCurrencyId: 51,
		currencies: [
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 165, name: "USD", isDefault: true }
		]
	},
	233: {
		defaultCurrencyId: 22,
		currencies: [
			{ id: 22, name: "BRL", isDefault: false },
			{ id: 34, name: "CNY", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 69, name: "IDR", isDefault: false },
			{ id: 72, name: "INR", isDefault: false },
			{ id: 126, name: "PHP", isDefault: false },
			{ id: 151, name: "THB", isDefault: false },
			{ id: 165, name: "USD", isDefault: true },
			{ id: 172, name: "VND", isDefault: false }
		]
	},
	239: { defaultCurrencyId: 51, currencies: [{ id: 51, name: "EUR", isDefault: true }] },
	240: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 167, name: "USDT", isDefault: false }
		]
	},
	253: { defaultCurrencyId: 51, currencies: [{ id: 51, name: "EUR", isDefault: true }] },
	254: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: true },
			{ id: 2, name: "AFN", isDefault: false }
		]
	},
	255: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: true },
			{ id: 2, name: "AFN", isDefault: false },
			{ id: 4, name: "AMD", isDefault: false }
		]
	},
	256: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: true },
			{ id: 4, name: "AMD", isDefault: false }
		]
	},
	257: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	258: { defaultCurrencyId: 2, currencies: [{ id: 2, name: "AFN", isDefault: true }] },
	259: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	260: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: false },
			{ id: 3, name: "ALL", isDefault: true }
		]
	},
	261: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: false },
			{ id: 4, name: "AMD", isDefault: true }
		]
	},
	262: { defaultCurrencyId: 2, currencies: [{ id: 2, name: "AFN", isDefault: true }] },
	263: { defaultCurrencyId: 2, currencies: [{ id: 2, name: "AFN", isDefault: true }] },
	264: { defaultCurrencyId: 2, currencies: [{ id: 2, name: "AFN", isDefault: true }] },
	265: { defaultCurrencyId: 155, currencies: [{ id: 155, name: "TOP", isDefault: true }] },
	266: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	267: {
		defaultCurrencyId: 4,
		currencies: [
			{ id: 4, name: "AMD", isDefault: true },
			{ id: 1, name: "AED", isDefault: false },
			{ id: 2, name: "AFN", isDefault: false },
			{ id: 3, name: "ALL", isDefault: false },
			{ id: 5, name: "ANG", isDefault: false },
			{ id: 6, name: "AOA", isDefault: false }
		]
	},
	268: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	269: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	270: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	271: {
		defaultCurrencyId: 4,
		currencies: [
			{ id: 4, name: "AMD", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	272: {
		defaultCurrencyId: 4,
		currencies: [
			{ id: 4, name: "AMD", isDefault: true },
			{ id: 2, name: "AFN", isDefault: false }
		]
	},
	273: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	274: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	276: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 4, name: "AMD", isDefault: false },
			{ id: 74, name: "IRR", isDefault: false },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 133, name: "RUB", isDefault: false },
			{ id: 157, name: "TRY", isDefault: false }
		]
	},
	277: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 51, name: "EUR", isDefault: false }
		]
	},
	278: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: false },
			{ id: 51, name: "EUR", isDefault: false },
			{ id: 131, name: "RON", isDefault: true }
		]
	},
	279: { defaultCurrencyId: 51, currencies: [{ id: 51, name: "EUR", isDefault: true }] },
	280: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	281: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	284: { defaultCurrencyId: 2, currencies: [{ id: 2, name: "AFN", isDefault: true }] },
	288: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	291: { defaultCurrencyId: 28, currencies: [{ id: 28, name: "BYN", isDefault: true }] },
	294: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	295: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	296: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	297: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	298: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	300: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	301: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 5, name: "ANG", isDefault: false },
			{ id: 3, name: "ALL", isDefault: false },
			{ id: 4, name: "AMD", isDefault: false }
		]
	},
	302: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	303: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: true },
			{ id: 4, name: "AMD", isDefault: false }
		]
	},
	304: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	305: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	306: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	307: { defaultCurrencyId: 5, currencies: [{ id: 5, name: "ANG", isDefault: true }] },
	308: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	309: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	310: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	311: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	312: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	313: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	314: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	315: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	316: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	317: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: false },
			{ id: 5, name: "ANG", isDefault: true }
		]
	},
	318: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	319: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	320: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	321: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	322: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	323: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 51, name: "EUR", isDefault: false }
		]
	},
	324: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 51, name: "EUR", isDefault: false }
		]
	},
	325: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	326: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	327: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	328: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	329: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	330: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	331: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	332: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	333: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	334: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	335: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	336: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	337: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	338: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	339: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	340: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	341: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	342: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	344: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	345: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	346: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	347: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	348: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	349: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	350: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	351: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	352: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	353: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	354: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	355: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	356: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	357: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	358: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	359: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	360: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	361: { defaultCurrencyId: 6, currencies: [{ id: 6, name: "AOA", isDefault: true }] },
	362: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	363: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	364: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	365: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	366: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	367: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	368: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	371: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	372: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	373: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	374: {
		defaultCurrencyId: 4,
		currencies: [
			{ id: 4, name: "AMD", isDefault: true },
			{ id: 165, name: "USD", isDefault: false }
		]
	},
	375: { defaultCurrencyId: 165, currencies: [{ id: 165, name: "USD", isDefault: true }] },
	376: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	377: { defaultCurrencyId: 1, currencies: [{ id: 1, name: "AED", isDefault: true }] },
	378: {
		defaultCurrencyId: 4,
		currencies: [
			{ id: 4, name: "AMD", isDefault: true },
			{ id: 12, name: "BBD", isDefault: false },
			{ id: 34, name: "CNY", isDefault: false },
			{ id: 75, name: "IRT", isDefault: false },
			{ id: 89, name: "KZT", isDefault: false },
			{ id: 133, name: "RUB", isDefault: false },
			{ id: 165, name: "USD", isDefault: false },
			{ id: 186, name: "ZWD", isDefault: false }
		]
	},
	379: { defaultCurrencyId: 4, currencies: [{ id: 4, name: "AMD", isDefault: true }] },
	380: {
		defaultCurrencyId: 165,
		currencies: [
			{ id: 165, name: "USD", isDefault: true },
			{ id: 51, name: "EUR", isDefault: false }
		]
	},
	381: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: true },
			{ id: 2, name: "AFN", isDefault: false },
			{ id: 15, name: "BGN", isDefault: false }
		]
	},
	382: {
		defaultCurrencyId: 1,
		currencies: [
			{ id: 1, name: "AED", isDefault: true },
			{ id: 2, name: "AFN", isDefault: false },
			{ id: 3, name: "ALL", isDefault: false }
		]
	},
	383: {
		defaultCurrencyId: 2,
		currencies: [
			{ id: 2, name: "AFN", isDefault: true },
			{ id: 4, name: "AMD", isDefault: false },
			{ id: 5, name: "ANG", isDefault: false }
		]
	}
};
